.simple-keyboard {
  margin: 0 auto;
}

.simple-keyboard .hg-button.hg-functionBtn.hg-button-space {
  max-width: none;
}
.react-simple-keyboard.hg-theme-default {
  background-color: #000;
}
.simple-keyboard .hg-button {
  height: 6rem;
  font-size: 1.75rem;
  font-family: "Manrope", sans-serif;
  color: #fff;
  background-color: #263238;
  font-weight: 600;

}

.simple-keyboard .hg-button:active {
  background: #1e2428 !important;
  color: #fff !important;
}

.simple-keyboard .hg-button-numbers {
  max-width: 12vw;
}

.simple-keyboard .hg-button.hg-functionBtn.hg-button-abc {
  max-width: 12vw;
}

.simple-keyboard .hg-button.hg-functionBtn.hg-button-ent {
  max-width: 12vw;
  font-weight: 800;
}
