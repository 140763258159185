body {
  margin: 0;
  padding: 0;
  font-family: "Manrope", "Open Sans", sans-serif;
  background-color: #000;
  color: #fff;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#the-app-content-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#the-app-content-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.MuiSnackbar-root .MuiAlert-icon {
  display: none;
}

.MuiSnackbar-root .MuiAlert-action {
  display: none;
}

html {
  font-size: 16px !important;
}

/* Hide cursor if on screen */

@media screen and (min-width: 972px) and (max-width: 1188px) and (min-height: 1728px) and (max-height: 2112px) {
  html {
    cursor: none !important;
  }
  .MuiButtonBase-root,
  .MuiModal-root,
  .MuiDialogContent-root,
  .MuiPaper-root,
  .MuiDialog-container
  {
    cursor: inherit !important;
  }
}

/*
html {
  font-size: 0.8rem;
}*/

/* Hide google maps popups */
.gm-style-iw, .gm-style-iw-a, .gm-style-iw-c {
  display: none !important;
}

p {
  margin: 0;
}

a {
  color: #0288D1;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
/*
html {
  font-size: 0.875rem !important;
}

@media only screen and (min-width: 1441px) {
  html {
    font-size: 1rem !important;
  }
}

@media only screen and (min-width: 2000px) {
  html {
    font-size: 1.125rem !important;
  }
}
*/
